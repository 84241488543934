.select-schedule {
    position: relative;
    padding-top: 40px;
    overflow-x: auto;
    width: 100%;
    padding: 30px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding-bottom: 0;
}

.select-schedule .head {
    display: flex;
    width: 100%;
}

.select-schedule .body {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    min-width: max-content;
}

.select-schedule .body .simplebar-content {
    display: flex;
}

.select-schedule .body .simplebar-content-wrapper {
    /* width: max-content!important;
    max-width: max-content!important; */
}

.select-schedule .body .simplebar-placeholder {
    width: 760px!important;
}

.select-schedule .day {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    height: max-content;
    min-width: 100px;
}

.select-schedule .day:nth-child(2) {
    border-left: 2px solid #fff;
}

.select-schedule .time {
    width: 60px;
}

.select-schedule .time .time-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 42px;
    font-weight: 600;
}

.select-schedule .head .day-name {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    flex: 1 1;
    min-width: 100px;
    color: #6C6C6C;
    text-transform: uppercase;
}

.select-schedule .day .time-hour {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(104, 51, 196, 0.15);
    border-bottom: 2px solid #fff;

}

.select-schedule .day .time-hour-part {
    height: 10px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-schedule .day .time-hour-part.active {
    background-color: #89ff9c;
}

.select-schedule .day .time-hour-part:hover {
    background-color: #35E551;
    opacity: .6;
}

.select-schedule .day .time-hour-part.active:hover {
    background-color: red;
}

.select-schedule .day .time-hour-part.changed.create {
    background-color: #35E551;
    opacity: 1;
}

.select-schedule .day .time-hour-part.changed.delete {
    background-color: red;
    opacity: 1;
}

.select-schedule .day .time-hour-part.active.selected {
    background-color: red;
    opacity: 1;
}

.select-schedule .day .time-hour-part.selected.delete {
    background-color: red;
    opacity: 1;
}


.select-schedule .day .time-hour-part.selected.create {
    background-color: #35E551;
    opacity: 1;
}

.select-schedule .day .time-hour-part.changed svg {
    color: #fff;
    font-size: 14px;
    margin: 0 auto;
}