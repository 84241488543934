.schedule {
    position: relative;
    overflow-x: auto;
    width: 450px;
}

.schedule-container {
    width: 100%;
    background: #F5F5F5;
    box-shadow: 0px 1px 2px rgba(0, 14, 51, 0.25);
    border-radius: 16px;
    padding: 15px 0;
}
.schedule-container .title {
    text-align: center;
    display: flex;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.schedule-container .body .simplebar-content {
    display: flex;
    max-height: 500px;
}
.schedule .head {
    display: flex;
    width: 100%;
}
.schedule .head .day-name {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    flex: 1 1;
    width: 50px;
}

.schedule .head .day-date {
    display: flex;
    padding: 2px 20px;
    font-weight: 600;
    /* color: var(--main-color); */
}

.schedule .head .day-name.active .day-date {
    color: var(--main-color);
}
.schedule .body {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    min-width: max-content;
}
.schedule-container .title button {
    background: none;
    border: none;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
    padding: 0 2px;
    transition: all .2s;
}

.schedule-container .title button:not([disabled]):hover {
    background-color: var(--main-color);
    color: #fff!important;
}

.schedule .body .day {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    height: max-content;
    width: 50px;
    text-align: center;
}

/* .schedule .day .time-hour-part {
    height: 10px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.schedule .body .day .day-name {
    font-weight: bold;
    padding: 15px;
    min-height: 100%;
}

.schedule .body .day .times {
    padding: 0 3px;
    min-height: 100%;
}

.schedule .body .day .times .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.schedule .body .day .times .time {
    color: var(--main-color);
    padding: 5px;
    border-radius: 50px;
    margin: 5px 0;
    cursor: pointer;
    transition: all .2s;
    font-weight: 600;
    font-size: 18px;
    color: #1F1F1F;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
    border-radius: 6px;
}

.schedule .body .day .times .time:not([disabled]):hover {
    color: #fff;
    background: var(--main-color);
}

.schedule .body .day .times .time.disabled {
    cursor: default!important;
    color: rgba(0, 0, 0, 0.5)!important;
    background-color: rgba(255, 255, 255, 0.3)!important;
}