@font-face {
    font-family: 'OpenSans';
    src: url('./OpenSans.ttf')  format('truetype')
}

@font-face {
    font-family: 'OpenSans Italic';
    src: url('./OpenSans-Italic.ttf')  format('truetype')
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Regular.ttf')  format('truetype')
}