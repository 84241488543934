.auth-block {
    min-height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  
  .auth-block h2 {
    margin-bottom: 72px;
    font-weight: 600;
    font-size: 42px;
  }

  .auth-block h3 {
    margin-bottom: 50px;
    font-size: 25px;
    text-transform: uppercase;
  }
  
  .input-auth {
    width: 100%;
    border: none;
    height: 62px;
    padding: 0 7px;
    font-size: 18px;
    border-bottom: 1px solid #D8D8D8;
    transition: all .2s;
  }
  
  .input-auth:focus {
    border-bottom: 1px solid var(--main-color);
  }
  
  .auth-block .form-label {
    display: flex;
  }
  
  .auth-block .form-label svg {
    cursor: pointer;
    transition: all .2s;
  }
  
  .auth-block .form-label svg:hover {
    opacity: .5;
  }
  
  .auth-card {
    width: 360px;
    padding: 0 15px;
    max-width: 100%;
  }
  
  .auth-card h2 {
    text-align: center;
  }
  
  .forgot-link {
    margin-top: 15px;
    text-align: center;
    color: #4D4D4D;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all .2s;
  }
  
  .forgot-link:hover {
    opacity: 0.5;
  }
  
  .add-link {
    text-align: center;
    color: #4D4D4D;
    font-size: 16px;
    margin-top: 15px;
  }
  
  .add-link span {
    color: var(--main-color);
    transition: all .2s;
    font-weight: 600;
    cursor: pointer;
  }
  
  .add-link span:hover {
    opacity: 0.5;
  }

  .auth-types {
    display: flex;
    margin: 10px 0;
    margin-bottom: 15px;
  }
  
  .auth-types .select-type {
    width: 440px;
    /* height: max-content; */
    padding: 40px 45px;
    background: #F6F6F6;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    transition: all .2s;
    cursor: pointer;
  }

  .auth-types .select-type:hover {
    background: #fff;
  }

  .auth-types .select-type img {
    max-width: 100%;
    max-height: 100%;
  }

  .auth-types .select-type h3 {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 600;
    color: #000000;
    margin-top: 40px;
  }

  .auth-block .steps {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto;
      height: max-content;
  }

  .auth-block .steps .step {
    background: #D8D8D8;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    font-family: Montserrat;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding: 1px 0;
    position: relative;
}

.auth-block .steps .step.active {
    background: var(--main-color);
}

.auth-block .steps .step .circle {
    border: 1px solid var(--main-color);
    width: 40px;
    height: 40px;
    position: absolute;
    top: -4px;
    left: -4px;
    border-radius: 50px;
    background-color: #fff;
    z-index: -1;
}

.auth-block .steps .line {
    height: 24px;
    width: 2px;
    background-color: #D8D8D8;
    display: block;
    margin: 0 auto;
    z-index: -2;
}

.auth-block .steps .line.active {
    background-color: var(--main-color);
}

.auth-block .steps .line.first {
    /* margin-bottom: 4px; */
}

.auth-block .steps .line.last {
    /* margin-top: 4px; */
}

.confirm-block img {
    margin: 0 auto;
    display: flex;
    margin-bottom: 55px;
}

.confirm-block .input-auth {
    text-align: center;
    color: var(--main-color);
    font-weight: 600;
    font-size: 25px;
}

.confirm-block .auth-card {
    width: 800px;
}

.confirm-block h2 {
    margin-bottom: 18px;
}