* {
  outline: none!important;
  font-family: OpenSans;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat;
}

body {
  margin: 0;
  padding: 0;
}
.white-color{
  color: #ffffff;
}

.content {
  padding-top: 80px;
  min-height: calc(100vh);
}

input::placeholder {
  color: #E4E4E4;
}
.grey-background{
  background-color: #F8F8F8;
}

.button {
  border-radius: 5px;
  padding: 8px 0;
  text-align: center;
  width: 152px;
  align-items: center;
  cursor: pointer;
  margin: 0 8px;
  transition: all .2s;
  border: none!important;
  display: inline-block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  box-sizing: border-box;
  /* height: 40px; */
  min-height: max-content!important;
  font-weight: 600;
  font-size: 13px;
}

.btn-filled {
  background: var(--main-color);
  color: #fff;
}
.white-button{
  background-color: none;
  background-color: transparent;
    color: #fff;
    border: 1px solid #fff !important;
}

.btn-filled:hover {
  background: #8462EF;
  color: #fff;
}

.btn-empty {
  background: none;
  color: var(--main-color);
  /* border: solid 1px #8462EF; */
    box-shadow:inset 0px 0px 0px 1px var(--main-color);
}

.btn-empty:hover {
  color: var(--main-color);
  /* border: solid 2px #8462EF; */
  box-shadow:inset 0px 0px 0px 2px var(--main-color);
  /* width: 148px;
  height: 36px; */
}

.btn-cancel {
  background: #FEF0F0;
  color: #FF3333;
}

.btn-cancel:hover {
  background-color: #fde0e0;
}

.btn-full {
  border: none;
  width: 100%;
  background: var(--main-color);
  color: #fff;
  margin: 0;
}

.btn-full:hover {
  width: 100%;
  background: #8462EF;
  color: #fff;
  margin: 0;
}
.white-text{
  color: #fff;
}
.white-text:hover{
  color: rgb(187, 187, 187);
}
.btn-circle {
  padding: 5px 6px;
}
.link-text{
  color: var(--main-color);
  font-weight: 700;

}
.link-text:hover{
  color: var(--main-color);
  font-weight: 700;
  
}

.check-box {
  cursor: pointer;
  width: 100%;
  display: flex;
  padding: 10px 18px;
}

.check-box a {
  font-weight: 600;
  color: var(--main-color);
  text-decoration: none;
}

.check-box a:hover {
  text-decoration: underline;
}

.check-box .box {
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid var(--main-color);
  margin-top: 4px;
  position: relative;
}

.check-box .box span {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: var(--main-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.check-box .check-box-label {
  padding-left: 15px;
}

.check-box p {
  margin: 0;
}

.select {
  position: relative;
  display: flex;
  align-items: center;
}

.select .values {
  z-index: 4;
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 300px;
  height: max-content;
  overflow-y: auto;
  opacity: 0;
  pointer-events: none;
  transition: all .2s;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 12px 0;
}

.select svg {
  position: absolute;
  right: 7px;
  color: #B6B6B6;
}

.select .values .value {
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  border-top: 2px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all .2s;
  padding: 11px 0;
}

.select .values .value:first-child {
  border: none;
}

.select .values .value:hover {
  color: #fff;
  background: var(--main-color);
}

.select .values .value.active {
  color: #fff;
  background: var(--main-color);
}

.select.active .values {
  opacity: 1;
  pointer-events: all;
}

.auth-block .input-simple {
  width: 100%;
}

.auth-block h2 {
  text-align: center;
}

.normal-font-size{
  font-size: 16px !important;
}

.auth-block .col-md-6 .form-label {
  color: #6C6C6C;
  font-weight: 600;
  font-size: 24px;
  margin: 30px 0 15px 0;
}

.auth-block .col-md-6 .avatar img, .auth-block .col-md-6 .avatar .avatar-name {
  border-radius: 8px!important;
}

.input-simple {
  width: auto;
  border: none;
  height: 38px;
  padding: 0 14px;
  font-size: 16px;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 10px;
  transition: all .2s;
  color: #6C6C6C;
  font-weight: 600;
}

.input-simple:focus {
  border: 1px solid var(--main-color);
}

.auth-block .form-label {
  color: #6C6C6C;
  font-size: 24px;
  font-weight: 600;
}

.input-simple .values {
  top: 38px;
}

textarea.input-simple {
  padding: 5px 14px;
}

.error-msg {
  color: red;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  padding: 0 7px;
  transition: all .2s;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  align-items: center;
  background: rgba(255, 255, 255, .5);
  z-index: 1000;
}

.loader .spinner-border {
  color: var(--main-color);
}

.find-tutor {
  position: relative;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.find-tutor h1 {
  text-align: left;
  font-weight: 600;
  width: 100%;
  margin: 20px 0 50px 0;
}

.tutor-card {
  height: max-content;
  padding: 23px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  background: #F7F7F7;
  border-radius: 6px;
  align-items: center;
}

.tutor-card .avatar-name {
  border-radius: 12px!important;
}

.tutor-card .avatar img {
  border-radius: 12px!important;
}

.tutor-card .languages {
  display: flex;
  color: #6C6C6C;
}

.tutor-card .languages span {
  font-weight: 600;
  color: #000;
  margin-left: 5px;
}

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-page .section {
  padding: 10px 25px;
}

.profile-info {
  height: max-content;
  padding: 40px;
  display: flex;
  background-color: #F7F7F7;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile-page .title {
  font-size: 42px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 15px;
}

.profile-page .pagination-box {
  width: 100%;
}

.profile-info .name {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.profile-info .avatar-name {
  border-radius: 8px!important;
}

.profile-info .specs {
  width: calc(100% - 40px);
  font-size: 13px;
  font-weight: 600;
  color: #6C6C6C;
}

.profile-info .about-heading {
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  width: 100%;
  margin-top: 5px;
}

.profile-info .about-text {
  font-size: 16px;
  color: #6C6C6C;
  margin-top: 15px;
  margin-bottom: 15px;
  white-space: pre-line;
}

.profile-info .since-heading {
  color: #6C6C6C;
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
}

.profile-info .since-year {
  color: #000;
  font-size: 22px;
  font-weight: 600;
}

.profile-info .button {
  margin: 30px 0;
}

.profile-info .specs .row {
  margin-bottom: 10px;
}

.profile-info .specs span {
  width: max-content;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
}

.avatar .avatar-name {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 200px;
  font-family: Montserrat;
}

.tutor-card .info {
  margin: 0px 20px;
}

.tutor-card .avatar .badge {
  margin-top: 10px;
  width: max-content;
}

.tutor-card .info .name {
  display: flex;
  margin-bottom: 0px;
  cursor: pointer;
  transition: all .2s;
  font-weight: 600;
  font-size: 30px;
  color: var(--main-color);
}

.tutor-card .info .name:hover {
  opacity: .6;
}

.rating {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  color: #FFCE31;
  align-items: center;
}

.rating span {
  font-size: 12px;
  font-weight: 500;
  color: #6C6C6C;
  margin-right: 5px;
}

.tutor-card .info .description {
  min-height: 70px;
  font-size: 14px;
}

.tutor-card .actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutor-card .actions .button {
  margin: 0;
  margin-bottom: 8px;
  width: 100%;
  padding: 5px 15px !important;
}

.tutor-card .price {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--main-color);
  text-align: center;
  color: #000;
  line-height: 40px;
  margin-top: 10px;
}

.tutor-card .price span {
  font-size: 42px;
  margin-right: 6px;
  margin-left: 6px;
  font-weight: 600;
}

.tutor-card .country {
  text-align: center;
  line-height: 15px;
  margin-top: 15px;
}

.tutor-filters {
  padding: 10px 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

.tutor-filters label {
  color: #6C6C6C;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.tutor-filters .col-md-4 {
  margin: 0;
  padding: 0;
}

.pagination-box {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.pagination {
  padding: 0;
  margin: 0;
}

.pagination .disabled, .pagination .active {
  pointer-events: none;
}

.pagination .disabled {
  opacity: 0.5;
}

.pagination .page-item .page-link {
  border-radius: 50px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  margin: 0 4px;
  color: #000;
  transition: all .2s;
  border: none;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.pagination .page-item.active .page-link {
  color: #fff;
  cursor: default;
  background: var(--main-color);
}

.pagination .page-item .page-link:hover {
  color: #fff;
  background: var(--main-color);
}
.home .five .examples div {
  text-transform: uppercase;
  font-size: 18px;
  color: #6C6C6C;
  letter-spacing: 1px;
    font-weight: 600;

}

.empty-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: max-content;
  height: max-content;
  text-align: center;
  color: var(--main-color);
}

.empty-data svg {
  font-size: 46px;
}

.header .my-profile {
  position: relative;
}

.header .my-profile .profile-options {
  opacity: 0;
  pointer-events: none;
  transition: all .2s;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 100%;
  width: max-content;
  z-index: 10;
  transform: translateX(-50%);
  padding-top: 12px;
}

.header .my-profile:hover .profile-options, .header .my-profile .profile-options:hover {
  opacity: 1;
  pointer-events: all;
}

.header .my-profile .profile-options .card a {
  padding: 8px 10px;
  text-align: left;
  text-decoration: none;
  color: var(--main-color);
  transition: all .2s;
}

.header .my-profile .profile-options .card a:hover {
  background: rgba(0,0,0,0.1);
}

.profile-page {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
}

.schedule-page .auth-card {
  margin: 20px 0;
}

.profile-page .section {
  margin-top: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-page .section .title {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  align-items: center;
}

.profile-page .main-info {
  margin: 10px 20px;
}

.profile-page .main-info .name {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  margin-bottom: 10px;
}

.profile-page .description {
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  white-space: pre-line;
}

.profile-page .actions {
  margin: 0 -8px;
}

.profile-page .update-avatar {
  position: relative;
  height: max-content;
}

.profile-page .update-avatar .pre-upload {
  cursor: pointer;
  pointer-events: none;
  transition: all .2s;
  opacity: 0;
  background: rgba(0,0,0,0.5);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 200px;
}

.profile-page .update-avatar .pre-upload svg {
  font-size: 40px;
}

.profile-page .update-avatar:hover .pre-upload {
  opacity: 1;
  pointer-events: all;
}

.chats-wrapper {
  padding-right: 0;
}

.dialog-wrapper {
  padding-right: 0;
  padding-left: 0;
}

.chat-page {
  min-height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  position: relative;
}

.chat-page .chat-list {
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  background-color: #F7F7F7;
}

.chat-block {
  height: 100%;
  position: relative;
}

.chat-dialog-item {
  display: flex;
  padding: 8px 22px;
  cursor: pointer;
  transition: all .2s;
  height: 90px;
  align-items: center;
}

.chat-seperator {
  height: 1px;
  width: auto;
  margin: 0 22px;
  background-color: rgba(0, 0, 0, 0.2);
}

.chat-dialog-item .avatar {
  min-width: 60px;
}

.chat-dialog-item.active {
  /* background: var(--main-color)!important; */
  /* color: #fff; */
  box-shadow: inset 6px 0px 0px 0px var(--main-color);
}

.chat-dialog-item:hover {
  background: rgba(0,0,0,0.1);
}

.chat-dialog-item .info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-dialog-item .add-info {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  padding-top: 3px;
  /* justify-content: center; */
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: max-content;
}

.chat-dialog-item .add-info .time {
  font-size: 14px;
  color: #6C6C6C;
  font-weight: bold;
}

.chat-dialog-item.active .info .name {
  color: var(--main-color);
}

.chat-dialog-item .badge {
  background-color: var(--main-color);
}

.chat-dialog-item .info .name {
  font-weight: bold;
  font-size: 24px;
  color: #000;
  font-family: Montserrat;
}

.chat-dialog-item .info .message {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #6C6C6C;
  font-size: 14px;
  font-weight: bold;
}

.chat-dialog {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.chat-dialog .chat-user {
  background: #F7F7F7;
  display: flex;
  padding: 15px 30px;
  cursor: pointer;
}

.chat-dialog .chat-user .user-info {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: center;
}
.chat-dialog .chat-user .user-info .name {
  font-weight: bold;
  font-size: 24px;
  font-family: Montserrat;
}

.chat-dialog .chat-user .user-info .online-status {
  color: rgb(139, 139, 139);
  font-weight: 600;
}

.chat-dialog .chat-user .user-info .online-status.active {
  color: #35E551;
}

.home .nine {
  position: relative;
  margin-bottom: 300px!important;
  padding-bottom: 200px!important;
}
.home .nine img {
  max-width: 870px!important;
  margin: auto!important;
  position: absolute;
  left: 0;
  right: 0;
  top: 260px;
}
.chat-dialog .chat-messages {
  height: calc(100vh - 321px);
  max-height: calc(100vh - 321px);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 30px;
}

.chat-dialog .date-at {
  width: max-content;
  font-size: 14px;
  text-align: center;
  margin: 5px auto;
  color: #fff;
  background: var(--main-color);
  padding: 2px 10px;
  border-radius: 50px;
}

.chat-dialog .chat-messages .chat-message {
  font-size: 14px;
  padding: 8px 10px;
  display: flex;
}

.chat-dialog .chat-messages .chat-message.my {
  flex-direction: row-reverse;
}

.chat-dialog .chat-messages .chat-message .message-text {
  font-size: 16px;
  padding: 8px 10px;
  display: flex;
  border-radius: 10px;
  padding: 6px 15px;
  font-weight: 600;
}

.chat-dialog .chat-messages .chat-message.other .message-text {
  color: #000;
  background: #F7F7F7;
}

.chat-dialog .chat-messages .chat-message.my .message-text {
  color: #fff;
  background: var(--main-color);
}

.chat-dialog .chat-messages .chat-message .avatar {
  cursor: pointer;
  min-width: 40px;
  margin-top: 4px;
}

.chat-dialog .chat-messages .chat-message .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat-dialog .chat-messages .chat-message.other .user-info {
  margin-left: 10px;
}

.chat-dialog .chat-messages .chat-message.my .user-info {
  margin-right: 10px;
}

.chat-dialog .chat-messages .chat-message .message-time {
  font-size: 12px;
  color: rgb(139, 139, 139);
  font-weight: normal;
  margin-left: 5px;
}

.chat-dialog .chat-messages .chat-message .status {
  display: flex;
  min-width: max-content;
  align-items: flex-end;
}

.chat-dialog .chat-messages .chat-message .status svg, .chat-dialog-item .status svg {
  font-size: 18px;
}


.chat-dialog .chat-messages .chat-message .user-info .name {
  font-weight: bold;
  cursor: pointer;
  color: var(--main-color);
}

.chat-dialog .chat-messages .chat-message .user-info .message-text {
  overflow-wrap: anywhere;
  white-space: pre-line;
}

.chat-dialog .chat-input {
  height: max-content;
  display: flex;
  border-top: 1px solid rgba(0,0,0,.125);
  padding: 25px;
  max-height: 230px;
  position: relative;
  min-height: 110px;
  align-items: center;
}

.chat-dialog .chat-input textarea {
  height: 50px;
  padding: 13px 14px;
  min-height: 50px;
  font-size: 14px;
  resize: none;
  background: #F7F7F7;
  border: none;
box-sizing: border-box;
border-radius: 25px;
padding-right: 70px;
}

.chat-dialog .chat-input button {
  width: 60px;
height: 60px;
  border-radius: 94px;
  position: absolute;
  padding: 0;
  border: none;
  right: 24px;
  bottom: 24px;
  margin: auto;
}

.chat-dialog .chat-input .custom-placeholder {
  position: absolute;
  bottom: 44px;
  font-size: 14px;
  left: 40px;
  pointer-events: none;
  opacity: .5;
}

.avatar .online-status {
  transition: all .2s;
  background: #28C936;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  position: absolute;
  top: 0px;
  right: 0px;
  border: 2px solid #fff;
}


.typing:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
}
.home .nine {
  background-color: #F7F7F7;
  text-align: center;
}

.home .nine {
  margin: 0 auto;
}
@keyframes ellipsis {
  to {
    width: 20px;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;    
  }
}

.mobile-menu {
  display: none;
}

.btn-back-mobile {
  display: none;
}

.profile-page .logout {
  display: none;
}

.categories {
  margin-bottom: 10px;
}

.categories .badge {
  margin-right: 5px;
}

.lesson-card {
  height: max-content;
  padding: 23px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  flex-wrap: wrap;
  background: #F7F7F7;
  border-radius: 6px;
}

.lesson-card .main-info {
  text-align: center;
}

.lesson-card .main-info .badge {
  margin: 0 10px;
}

.lesson-card .members {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lesson-card .avatar img {
  border-radius: 8px;
}

.lesson-card .members .member {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s;
}

.lesson-card .members .member:hover {
  opacity: .7;
}

.lesson-card .members .member .title {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
  color: var(--main-color);
}

.lesson-card .actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.home .seven h3, .home .eath h3, .home .nine h3 {
  color: var(--main-color);
  font-weight: 600;
  font-size: 28px;
}
.lesson-card .actions .button {
  margin: 6px 0;
}

@media (max-width: 768px) { 
  .img-fluid-about {
  
    float: left !important;
  }
  .m-order-0 {
    order: 0;
  }
  .home .six .stat {
    font-size: 94px!important;
  }
  .tutor-filters .col-md-4 {
    margin-bottom: 20px;
  }

  .book-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
  }

  .home .seven .col-md-8 {
    order: 2;
  }
  .home .seven .col-md-4, .home .eath .col-md-4 {
    text-align: center;
  }
  .home .eath .col-md-8 {
    justify-content: center!important;
  }

  .book-modal .schedule {
    width: 100%;
  }

  .home .one .col-md-6 {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .home .one .col-md-6 p {
    padding: 0;
    text-align: center;
  }
  .home .one .col-md-6 .actions {
    margin-top: 20px;
  }

  .home .shadow-tab {
    width: 100%!important;
  }

  .home .five span {
    margin-right: 8px!important;
  }
  .home .five .examples div {
    font-size: 18px;
  }
  


  .tutor-card .languages {
    display: flex;
    flex-direction: column;
  }
  .home .five .examples .col-6 {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
  }

  .auth-types .select-type {
    width: 270px!important;
    height: 250px!important;
    padding: 20px!important;
    padding-top: 60px!important;
    margin-bottom: 20px!important;
  }
  .auth-types .select-type h3 {
    margin: 5px 0!important;
  }

  .auth-block .steps {
    display: flex;
    position: unset!important;
    order: 100;
    margin: 0 auto!important;
    margin-bottom: 40px!important;
    
  }

  .auth-block .steps .line {
    width: 24px!important;
    height: 2px!important;
    margin: 15px 0!important;
  }


  .home .section h2, .home .section h1 {
    text-align: center;
  }
  .home .section img {
    order: 0;
  }

  /* .header {
    bottom: 0;
    border-bottom: unset!important;
    border-top: 1px solid var(--main-color);
    z-index: 20!important;
  } */

  /* .header .container {
    display: none;
  } */

  /* .mobile-menu {
    display: flex;
    height: 100%;
    z-index: 2;
    background-color: #fff;
  } */

  .chats-wrapper {
    padding: 0;
  }

  .mobile-menu .mobile-menu-btn .badge{
    top: 15px!important;
    left: calc(50% + 20px)!important;
  }

  .mobile-menu .mobile-menu-btn svg {
    font-size: 36px;
    color: var(--main-color);
  }

  /* .content {
    padding-top: 0;
    padding-bottom: 60px;
  } */

  .home .one .col-md-6:nth-child(1) {
    /* margin-top: 20px!important; */
    order: 2;
  }

  .register-block {
    flex-direction: column!important;
    align-items: center;
  }

  .register-block .auth-card {
    order: -1;
    margin-bottom: 40px;
  }

  .register-block img {
    margin-bottom: 40px;
  }

  .home .one {
    padding-top: 0!important;
  }

  .home .one img {
    margin-bottom: 20px!important;
  }

  .home .three img, .home .four img, .home .five img {
    margin: 20px 0!important;
  }

  .auth-block .auth-types {
    flex-direction: column!important;
  }
  
  .m-hide {
    display: none;
  }

  .chat-block, .chat-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100vh - 120px);
  }

  .btn-back-mobile {
    display: block;
    height: max-content;
    align-self: center;
    width: max-content;
    margin-right: 15px;
    color: var(--main-color);
  }

  .chat-messages {
    background-color: #fff;
  }
  .chat-input {
    background-color: #fff;
  }

  .btn-back-mobile:hover {
    color: var(--main-color);
  }

  .update-avatar {
    margin: 0 auto;
  }
  
  .profile-page .main-info {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
  }

  .profile-page .name {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .profile-page .name .rating {
    margin-top: 5px;
  }

  .profile-page .logout {
    display: block;
    text-align: center;
  }

  .profile-page .section {
    width: 100%;
  }

  .home .two .col-md-4 {
    margin-bottom: 50px;
  }

  .lesson-card {
    flex-direction: column;
    align-items: center;
  }

  .lesson-card .members .member {
    margin: 0 15px;
  }

  .tutor-card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tutor-card .times {
    flex-direction: column;
    align-items: center;
  }

  .tutor-card .avatar {
    align-self: center!important;
    margin-bottom: 15px;
  }

  .tutor-card .name {
    flex-direction: column;
  }
  .tutor-card .rating {
    margin: 0;
    margin-top: 5px;
  }

  .tutor-card .actions {
    flex-direction: column;
  }

  .tutor-card .actions .button {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 5px;
  }

  .tutor-card .price {
    text-align: center;
    display: block;
    width: 100%;
    flex: 1 1;
    margin: 0 auto;
  }

  .pagination-box {
    width: 100%;
  }
}

@media (max-width: 480px) { 


}
.select-schedule .actions {
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding: 10px 0;
  align-items: center;
}
.select-schedule .actions p {
  color: #6C6C6C;
  margin: 0;
  font-weight: 600;
  font-size: 17px;
}
.select-schedule .actions button {
  margin-right: 0;
}

.book-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
}

.powered-by-paypal {
  font-style: italic;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: nowrap;
  min-width: max-content;
  flex-direction: row;
  margin-top: 7px;
  font-size: 13px;
}

.powered-by-paypal img {
  width: 65px;
  margin-left: 10px;
}

.set-rating {
  width: 100%;
  text-align: center;
}

.set-rating svg {
  font-size: 40px;
  cursor: pointer;
  color: #FFCE31;
}

.section .pagination-box {
  width: 100%;
}

.review-card {
  padding: 40px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);;
}

.review-card .user {
  display: flex;
  align-items: center;
}

.review-card .user .name {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  line-height: 20px;
  font-weight: 600;
}

.review-card .user .name span {
  font-size: 14px;
  /* display: contents; */
  display: flex;
  /* opacity: .5; */
  align-items: center;
  color: #6c6c6c;
  font-weight: 600;
  margin-top: 4px;
}

.review-card .user .name span .rating {
  margin-right: 15px;
}

.review-card .user .rating svg {
  color: #FFCE31;
}

.review-card .text {
  margin-top: 5px;
  padding-left: 65px;
  white-space: pre-line;
}

.home .section {
 padding: 80px 0;
}
.grey-background .one{
  padding: 80px 0 0 0;
}

.home .section .text {
  font-weight: 700;
  font-size: 26px;
  color: var(--main-color);
  padding-right: 120px;
}

.home .section .text span {
  font-weight: 800;
  font-size: 30px;
}

.map-bg::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/map.svg) no-repeat;
  background-size: auto 100%;
  background-position: center;
  opacity: 0.03;
}

.map-bg p:first-child{
  margin-bottom: 0.5rem;
    letter-spacing: .1em;
    font-family: inherit;
    font-weight: 700;
 
    line-height: 1.6;

}
.map-bg h2, h4{
  margin-bottom: 0.5rem;
      font-family: inherit;
      font-weight: 700;
      line-height: 1.2;
      color: inherit;
}
.map-bg i {
  color: var(--main-color);
  margin-bottom: 20px;
}

.CTA-bacground{
  background-image: url("../../assets/images/bg-cta.png");
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 50px;
}

.blockquote-text {
  padding: 2.5rem;
  border: 2px solid #e9ecef;
  border-radius: 0.25rem;
  position: relative;
  font-size: 14px;
}
.blockquote-footer cite {
  font-family: "Poppins", sans-serif;
  display: block;
  line-height: 1;
  color:var(--main-color);
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
}

.card{
  border: none;
}
.testimonials .card-body{
 background-color: transparent;
}
.testimonials i{
  color: var(--main-color);
  margin-bottom: 15px;
}
.testimonials .h6{
  font-weight: 900;
    line-height: 1.2;
    font-size: 1rem;
    letter-spacing: .1em;
    color: var(--main-color);

}

.blockquote-footer::before {
  content: "";
}

.blockquote-footer {

padding: 1.5rem 0rem 0rem 0rem;
}
.blockquote-text::after {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #fff;
  position: absolute;
  bottom: -10px;
  left: 2rem;
  display: block;
}
.blockquote-text::before {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #e9ecef;
  position: absolute;
  bottom: -15px;
  left: 2rem;
  display: block;
}
.testimonials h3{
    font-weight: 900;
    line-height: 1.2;
  margin-bottom: 10px;
}
.testimonials .card-body{
  padding:2rem 1rem;
}
.home .section img {
  max-width: 100%;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
}

.home .one {
  align-items: center;
}

.home h1 {
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.home .one h5{
  text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    display: inline-block;
    letter-spacing: 2px;
    margin-bottom: 10px;

}
.home h1 span {
  color: var(--main-color);
}

.home .one p {
  font-size: 24px;
  color: #6C6C6C;
  padding-right: 100px;
}

.home .one .actions {
  margin-top: 50px;
}
.two-new p{
  letter-spacing: 1px;
  font-weight: 600;
}

.home .two {
  background-color: #F7F7F7;
}
/* svg path:hover{
  fill: #7722BC;
} */
svg:hover ~ path  {
  fill: #7722bc;
}

svg:hover + path {
  fill: #7722bc;
}

svg:hover > path{
  fill: #7722bc;
}

svg:hover > path { fill: #7722bc; }


svg:hover + path { fill: #7722bc; }


svg:hover path { fill: #7722bc; }

svg:hover ~ path { fill: #7722bc; }


.red-btn{
  background-color: #F2234A;
}
.red-btn:hover{
  background-color: #f72d52;
}

.home .counter-section2 h3{
  font-size: 48px;
  color: var(--main-color);
  font-weight: 600;
}

.grey-text-color{
  color:#848887;
}

.home .two .col-md-4 {
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
}

.home .two .col-md-4 div {
  font-size: 20px;
  margin-top: 34px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  /* height: 80px; */
  display: inline-block;
  text-align: center;
}

.home .three h2, .home .four h2, .home .five h2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 26px;
}

.home .three p, .home .four p, .home .five p {
  color: #6C6C6C;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

/* .home .five .examples div span {
  display: inline-block;
  margin-right: 30px;
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: #000;
} */

.home .six {
  text-align: center;
}

.home .six h3 {
  font-weight: 600;
}

.course-cards{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 5px 25px -2px rgb(0 0 0 / 18%);
  padding: 60px 20px;
  margin-bottom: 20px;

}
.course-cards span{
  padding: 05px 10px;
  background-color: #F8F8F8;
  border-radius: 5px;
  color:var(--main-color);
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 14px;
}
.course-cards h5{
  font-size: 24px;
  font-weight: 800;
  
}


.home .six .stat {
  display: flex;
  flex-direction: row;
  color: var(--main-color);
  font-weight: 600;
  font-size: 160px;
  width: max-content;
  margin: 0 auto;
  align-items: center;
}

.home .six .stat img {
  margin-left: 35px;
}

.home .six p {
  color: #6C6C6C;
  font-size: 25px;
}

.privacy {
  padding: 0 100px;
  padding-bottom: 150px;
}

.privacy h1 {
  margin: 60px 0;
  text-align: center;
  font-weight: 600;
}

.privacy p {
  font-size: 24px;
}

.key-take-away {
  display: flex;
  margin-top: 20px;
}

.key-take-away span {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: var(--main-color);
  display: block;
  margin: 10px 0;
  margin-right: 20px;
  min-width: 8px;
}

.key-take-away p {
  overflow-wrap: anywhere;
  color: #6C6C6C;
  font-weight: 600;
  font-size: 16px;
}

.key-take {
  position: relative;
}

.key-take .add {
  height: 38px;
  border: 1px solid var(--main-color);
  border-radius: 10px;
  position: absolute;
  right: 0;
  background-color: #fff;
}

.subject-avatar {
  border-radius: 8px;
  overflow: hidden;
}

.subject-avatar .subject-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subject-avatar .subject-avatar-name {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subject-avatar .subject-avatar-name img {
  width: 50%;
}

.modal-content {
  border: none;
}

.modal-header {
  text-align: center;
  border: none;
}

.modal-title {
  font-size: 21px;
  color: #000;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.book-modal .times {
  display: flex;
}

.book-modal .times p {
  font-weight: 600;
  flex: 1 1;
  text-align: center;
}

.book-modal .times p span {
  color: var(--main-color);
  font-size: 21px;
}

.modal-footer {
  border: none;
}

.tutor-card .info .tutor .avatar img {
  border-radius: 50px!important;
}

.tutor-card .info .tutor .name {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-left: 15px;
  text-transform: uppercase;
  line-height: 58px;
}

.tutor-card .times {
  display: flex;
}

.tutor-card .times p {
  font-weight: 600;
  flex: 1 1;
  text-align: left;
}
.tutor-card .times p span {
  color: var(--main-color);
  font-size: 21px;
}

.with-pattern {
  position: relative;
}

.with-pattern::before {
  content: '';
  display: block;
  width: 40vw;
  padding-bottom: 15%;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/pattern.svg) repeat;
  background-size: 20rem;
  background-position: top left;
  opacity: 0.07;
}
.hero .text-primary{
  margin-bottom: 0.5rem;
  letter-spacing: .1em;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.2;
}

.hero h1{
  font-weight: 900;
}
.hero h4{
  font-weight: 700;
}

.pulled-up-section {
  margin-top: -4rem;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.list-check-white li::before {
  color: #fff;
}
/* .list-check li::before {
  content: '\f00c';
  display: block;
  margin-right: 0.5rem;
  text-align: center;
  color: var(--main-color);
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  text-shadow: 0 1px 1px rgb(12 218 144 / 30%);
} */
.card-body{
  background-color: var(--main-color);
}
.media-body{
  margin-left: 10px;
}
.icon-block {
  width: 90px;
    height: 57px;
  border-radius: 0.25rem;
  background: #6930c31f;
  
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--main-color);
  font-size: 0.9rem;
}
.media-body p{
  font-size: 14px;
}
.media-body h5, h2{
  font-weight: 700;
}
.text-primary {
  color: var(--main-color) !important;
}
.has-quote{
  position: relative;
}

.has-quote::before {
  content: '\f10d';
  display: block;
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  color: #000;
  opacity: 0.05;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 8rem;
  z-index: 2;
}

.shadow {
  box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 8%) !important;
}

.img-gradient {
  position: relative;
  z-index: 3;
}

.img-gradient::before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 30%;
  position: absolute;
  bottom: -2rem;
  left: -2rem;
  background: linear-gradient(45deg, #6930c3, #6930c3);
  border-radius: 0.25rem;
  z-index: -1;
  opacity: 0.5;
}
.img-fluid-about{
  width: 80%;

    float: right;
}
.img-fluid-about2{
  width: 80%;
}
.icon-block-lg {
  width: 112px;
  height: 80px;
  max-width: 112px;
  min-width: 112px;
}
.img-gradient-left::before, .img-gradient-right::before {
  width: 70%;
  height: 70%;
  padding-bottom: 0;
}
.bg-primary{
  background-color: var(--main-color) !important;
}
.with-pattern-full::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/pattern.svg) repeat;
  background-size: 20rem;
  background-position: top left;
  opacity: 0.04;
}
.img-gradient-right::before {
  left: auto;
  right: -2rem;
}


.about .one {
  background-image: url(../images/about-top.png);
  height: 330px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.about .one h2 {
  font-weight: 600;
  font-size: 42px;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: max-content;
  height: max-content;
}

.about .two {
  padding: 80px 40px;
  font-size: 24px;
  text-align: center;
  color: #6C6C6C;
}

.about .three p, .about .four p, .about .five p {
  font-size: 24px;
  color: #6C6C6C;
}

.about .three h2, .about .four h2, .about .five h2 {
  font-weight: 600;
  font-size: 45px;
  text-align: center;
  color: var(--main-color);
  margin: 50px 0 150px;
}

.about .three, .about .four, .about .five {
  margin-bottom: 50px;
}

.about .three img, .about .four img, .about .five img {
  width: 100%;
}

.home .shadow-tab {
  background: #FFFFFF;
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.26);
border-radius: 9px;
width: 80%;
margin: 130px auto;
display: flex;
flex-direction: column;
align-items: center;
padding: 24px;
}

.home .shadow-tab img {
  width: 240px;
  margin-bottom: 25px;
}

.home .shadow-tab p {
font-size: 15px;
text-align: center;

color: #6C6C6C;
margin-bottom: 25px;
}

.home .shadow-tab div {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  color: #6930C3;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: calc(100vh - 120px); */
}
.contact .hero{
  width: 100%;
}
.contact .pulled-up-section{
  width: 100%;
}
.contact h3{
  color: #000 !important;
  font-weight: 700;
}
.contact .text-muted a{
  color: #000 !important;
}
.contact .card-body{
  background-color: #fff !important;
}

.contact .form-control {
  padding: 1.275rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #eee;
  height: calc(1.5em + 0.75rem + 2px);
  box-shadow: inset 0 0 0 0.5px #eee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contact .form-group {
  margin-bottom: 1rem;
}
.contact textarea.form-control {
  height: auto;
}
.contact .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--main-color);
  outline: 0;
  box-shadow: 0 0 0 0.5px var(--main-color);
}

.faq h2 {
  font-weight: 600;
  font-size: 45px;
  text-align: center;
  color: #19191B;
  margin: 120px 0;
}

.faq .question {
  background: #FFFFFF;
  box-shadow: 36px 50px 200px rgba(25, 3, 1, 0.04);
  padding: 40px 100px;
  cursor: pointer;
}

.faq .questions h4 {
  font-weight: 600;
  font-size: 26px;
  color: #6C6C6C;
  margin: 65px 0 25px;
}

.faq .question .question-title {
  font-weight: 600;
  font-size: 18px;
  color: #323B4B;
  transition: all .2s;
}

.faq .question .question-title svg {
  font-size: 28px;
  float: right;
  transition: all .2s;
}

.faq .question.active .question-title svg {
  transform: rotateX(180deg);
}

.faq .question .question-answer {
  font-weight: bold;
  font-size: 14px;
  color: #6C6C6C;
  transition: all .2s;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.faq {
  padding-bottom: 50px;
}

.faq .question.active .question-title {
  margin-bottom: 40px;
}

.faq .question.active .question-answer {
  height: auto;
  opacity: 1;
}