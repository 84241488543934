.footer {
    background: #212121;
    padding: 100px 0 80px 0;
    color: #fff;
}

.footer .logo {
    filter: brightness(100);
    height: 40px;
}

.footer h5 {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}

.footer a {
    font-size: 14px;
    margin: 8px 0;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    display: block;
}

.footer a:hover {
    text-decoration: underline;
}

.footer .box {
    margin-bottom: 60px;
    padding: 0 40px;
}

.footer .left-line {
    border-left: 2px solid rgba(255, 255, 255, 0.21);
}

.footer .payment-logo {
    height: 24px;
}

@media (max-width: 1199px) { 
    .footer .row {
        flex-direction: column;
        align-items: center;
    }

    .footer .row .col {
        margin-bottom: 60px;
    }

    .footer .row .left-line {
        border: none;
        /* border-bottom: 2px solid rgba(255, 255, 255, 0.21); */
        /* margin-bottom: 50px; */
    }

    .footer .row .left-line .box {
        border-bottom: 2px solid rgba(255, 255, 255, 0.21);
        padding-bottom: 50px;
    }

    .footer .row .col-auto {
        width: 360px;
    }

    .footer .row .col-auto .logo {
        margin: 0 auto;
        display: block;
    }

    /* .footer */
}

@media (max-width: 680px) { 
    .footer-logo{
        padding: 40px;
    }
    .socials-links {
        margin: 0 auto;
    }
}

.socials-links {
    width: max-content;
   
    margin-bottom: 25px;
}

.socials-links a {
    display: inline-block;
    border-radius: 50px;
    margin: 0 15px 0px 0px;
    box-sizing: border-box;
    margin-top: 15px;
}

.socials-links a img {
    height: 23px;    
    width: 23px;
    object-fit: contain;
    opacity: 0.5;
    transition: all .2s;
}

.socials-links a:hover img {
    opacity: 1;
}