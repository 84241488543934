.header {
    height: 80px;
    position: fixed;
    z-index: 5;
    background: #F7F7F7;
    width: 100%;
    display: flex;
    align-items: center;
    backdrop-filter: blur(4px);
}

.ct-new-columns {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
}
.left-div-header{
    width: 50%;
    flex-direction: row;
    display: flex;
    padding-bottom: 0px;
    padding-top: 0px;
}
.right-div-header {
    width: 50.00%;
    padding-bottom: 0px;
    padding-top: 0px;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
}
.mini-header-social-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
}

.mini-header-social-icons a {
    font-size: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
   
}
.mini-header-social-icons a {
    line-height: 1;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.left-div-inner-header {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 30px;
}
.right-div-inner-header {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-left: 0px;
}
.header .logo {
    height: 60px;
}

.header .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.menu-close-icon {
    position: absolute;
    top: 3%;
    right: 10.5%;
}

.header .add-menu a {
    color: #000;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 17px;
    cursor: pointer;
    text-decoration: none;
    margin: 0 20px;
    transition: .2s;
}

.header .add-menu a:hover {
    color: var(--main-color);
    border-bottom: 2px solid var(--main-color);
}

.header .logo img {
    height: 40px;
}

.header .auth-option-text {
    color: var(--main-color);
}

.header .user-name {
    display: inline-block;
    font-size: 22px;
    color: #666666;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 14px;
    margin-left: 14px;
}

.header .avatar {
    display: inline-block;
}

.header .header-link {
    color: #6C6C6C;
    width: 30px;
    padding: 10px 20px;
    transition: all .2s;
}

.header .header-link.active {
    color: var(--main-color);
}

.header .header-link svg {
    font-size: 28px;
}

.menu-toggle {
    display: none;
}

@media (max-width: 991px) { 
    .header .user-name {
        display: none;
    }
    .header .avatar {
        margin-left: 15px;
    }

       
        
}

@media (max-width: 1399px) { 
    .auth-block .btn-cancel {
        margin-left: 36px;
        margin-top: 10px;
      }
      
}
@media (max-width: 1199px) { 
    .header .user-name {
        display: none;
    }
    .menu-toggle {
        display: block;
        width: 18px;
        max-width: 18px;
        height: 18px;
        position: relative;
        padding: 0;
    }
    .header .menu {
        display: none;
    }
    
    .menu-toggle .sq-1, .menu-toggle .sq-2, .menu-toggle .sq-3, .menu-toggle .sq-4 {
        width: 8px;
        height: 8px;
        border-radius: 3px;
        background-color: var(--main-color);
        position: absolute;
    }

    .menu-toggle .sq-1 {
        top: 0;
        left: 0;
    }
    .menu-toggle .sq-2 {
        top: 0;
        right: 0;
        background-color: #4D4D4D;
    }
    .menu-toggle .sq-3 {
        bottom: 0;
        left: 0;
    }
    .menu-toggle .sq-4 {
        right: 0;
        bottom: 0;
    }
    .header .mobile-menu {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        padding: 0 30px;
        padding-top: 120px;
    }

    .header .mobile-menu .mobile-menu-btn {
        padding: 20px;
        border-bottom: 2px solid rgba(172, 172, 172, 0.21);
        font-size: 20px;
        font-weight: 600;
        color: #4D4D4D;
    }
    .header .mobile-menu .mobile-menu-btn.active {
        color: var(--main-color);
    }
    .align-items-center{
        justify-content: space-between;
    }
    .mobile-menu .mobile-menu-btn {
        display: flex;
        flex: 1 1;
        justify-content: center;
        align-items: center;
        color: var(--main-color);
        text-decoration: none;
        position: relative;
      }
    
 

      
}
@media (min-width: 992px){

    .header .add-menu a {
    
      font-size: 16px;
    
      margin: 0 10px;
    
    }
    }

@media (max-width: 991px) { 
   

   
    
    .add-menu * {
        display: none;
    }
   

}

.header-link.active img {
    filter: invert(18%) sepia(57%) saturate(4140%) hue-rotate(234deg) brightness(84%) contrast(90%);
}